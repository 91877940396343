import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { AppService } from '../app/app.service';
import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShopcartService } from '../shopcart/shopcart.service';

@Injectable({
  providedIn: 'root',
})
export class AddonService {
  private addonList: IAddon[] = [];

  private addonSubject: BehaviorSubject<IAddon[]> = new BehaviorSubject(
    this.addonList
  );

  public readonly addons$: Observable<IAddon[]> =
    this.addonSubject.asObservable();

  constructor(
    private appService: AppService,
    private httpService: HttpService,
    private shopCartService: ShopcartService
  ) {}

  async getAddonList(productId: number): Promise<IAddon[]> {
    const { data, length } = await this.httpService.getAddonList(productId);
    let shopcart = this.shopCartService.getShopcart();

    const addonListFormated: IAddon[] = data.map((addon) => {
      return {
        addonId: addon.addonId,
        addonName: addon.addonName,
        addonDescription: addon.addonDescription,
        addonImage: addon.imageUrl,
        locationId: addon.locationId,
        locationName: addon.locationName,
        selected: false,
        discountTotal: 0,
        subtotal: 0,
        total: 0,
        availablePaxes: 0,
        paxList: addon.paxList.map((pax) => {
          let paxQuantity = 0;
          let productShopcartIndex = shopcart.productList.findIndex(
            (product) =>
              product.paxList.findIndex(
                (paxShopcart) => paxShopcart.id === pax.paxId
              ) !== -1
          );

          if (productShopcartIndex != -1) {
            let paxShopcartIndex = shopcart.productList[
              productShopcartIndex
            ].paxList.findIndex((paxShopcart) => paxShopcart.id === pax.paxId);
            paxQuantity =
              paxShopcartIndex != -1
                ? shopcart.productList[productShopcartIndex].paxList[
                    paxShopcartIndex
                  ].quantity
                : 0;
          }

          return {
            paxId: pax.paxId,
            paxName: pax.paxName,
            paxQuantity: paxQuantity,
            paxPrice: pax.paxPrice,
            paxTotal: 0,
            paxSubtotal: 0,
            paxDiscount: 0,
            paxPriority: pax.paxPriority,
            paxBlocked: false,
          };
        }),
      };
    });

    if (shopcart.comboId || (this.addonList && this.addonList.length == 0)) {
      this.addonList = [...this.addonList, ...addonListFormated];
    } else {
      this.addonList = [...addonListFormated];
    }

    this.addonSubject.next(this.addonList);

    return addonListFormated;
  }

  public cleanState() {
    this.addonList = [];
    this.addonSubject.next(this.addonList);
  }

  public validateMaxPaxPerAddon(addonId: number, quantity: number) {
    let addonIndex = this.addonList.findIndex(
      (addonReference) => addonReference.addonId == addonId
    );
    this.addonList[addonIndex].availablePaxes = this.addonList[
      addonIndex
    ].paxList.reduce((counter, pax) => counter + pax.paxQuantity, 0);
    this.addonSubject.next(this.addonList);
  }

  public addAddon(addon: IAddon) {
    let addonIndex = this.addonList.findIndex(
      (addonReference) => addonReference.addonId == addon.addonId
    );
    this.addonList[addonIndex] = { ...addon };
    this.addonSubject.next(this.addonList);
    this.shopCartService.addAddon(addon);
  }

  public removeAddon(addon: IAddon) {
    this.shopCartService.removeAddon(addon);
  }

  public getCurrentAddons(): IAddon[] {
    return this.addonList;
  }
}
