/* ANGULAR NATIVE */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* INTERCEPTORS */
import { AuthApiInterceptor } from './interceptors/auth-api.interceptor';

/* ANGULAR MATERIAL */
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatCardModule} from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';

/* external components */
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxPayPalModule } from 'ngx-paypal';

/* Translate service */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


/* COMPONENT */
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FooterHomeComponent } from './components/general/footer-home/footer-home.component';
import { MitComponent } from './components/payment/mit/mit.component';
import { PaymentErrorComponent } from './components/payment/payment-error/payment-error.component';
import { ShopcarResumeComponent } from './components/shopcar-resume/shopcar-resume.component';
import { PersonalInformationViewComponent } from './views/personal-information/personal-information.view.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { TermsConditionsFormComponent } from './components/terms-conditions-form/terms-conditions-form.component';
import { AddonCardComponent } from './components/addon-card/addon-card.component';
import { SelectPaxMobileComponent } from './components/select-pax-mobile/select-pax-mobile.component';
import { SelectPaxComponent } from './components/select-pax/select-pax.component';
import { BookingComponent } from './views/booking/booking.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { SelectScheduleComponent } from './components/select-schedule/select-schedule.component';
import { SelectBookingPaxComponent } from './components/select-booking-pax/select-booking-pax.component';
import { FooterComponent } from './components/footer/footer.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AddonViewComponent } from './views/addon/addon.view.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PaymentViewComponent } from './views/payment/payment.view.component';
import { PaymentIframeComponent } from './components/payment-iframe/payment-iframe.component';
import { PaypalComponent } from './components/paypal/paypal.component';
import { ErrorMessageComponent } from './components/general/error-message/error-message.component';
import { MitPaymentComponent } from './components/payment/mit-payment/mit-payment.component';
import { CloseDialogComponent } from './components/general/close-dialog/close-dialog.component';
import { ReservationConfirmationComponent } from './components/payment/reservation-confirmation/reservation-confirmation.component';

/* localize */
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es-MX'
import { AppService } from './services/app/app.service';
import { AuthService } from './services/auth/auth.service';
import { environment } from '../environment/environment';
import { AngularFireModule } from '@angular/fire/compat';

import { KlaviyoService } from './services/klaviyo/klaviyo.service';

import { SafePipe } from './pipes/safe.pipe';
import { OfflineComponent } from './views/offline/offline.component';
import { SupportComponent } from './views/support/support.component';
import { SupportReservationComponent } from './views/support-reservation/support-reservation.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { MatButtonModule} from '@angular/material/button';
import { LoaderComponent } from './components/loader/loader.component';
import { PaymentLinkViewComponent } from './views/payment-link/payment-link.view.component';
import { ButtonComponent } from './components/button/button.component';
import { CardTransportationComponent } from './components/card-transportation/card-transportation.component';
import { SelectComponent } from './components/select/select.component';
import { TagComponent } from './components/shared/tag/tag.component';
import { TagActionComponent } from './components/shared/tag-action/tag-action.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ThemeService } from './services/theme/theme.service';

registerLocaleData(localeEs,'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initialize(appConfig: AppService,authService : AuthService)
{
  const url = new URL(window.location.href);
  const token = url.searchParams.get('tk') as string;
  authService.setToken(token);
  return () => appConfig.getConfig();
}

export function initializeLocale(appConfig: AppService) {
  return appConfig.getCurrentLang();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PaypalComponent,
    FooterHomeComponent,
    MitComponent,
    PaymentErrorComponent,
    ErrorMessageComponent,
    MitPaymentComponent,
    CloseDialogComponent,
    ReservationConfirmationComponent,
    AddonViewComponent,
    BreadcrumbComponent,
    PaymentViewComponent,
    ShopcarResumeComponent,
    PaymentIframeComponent,
    PersonalInformationViewComponent,
    SafePipe,
    UserFormComponent,
    TermsConditionsFormComponent,
    AddonCardComponent,
    SelectPaxMobileComponent,
    SelectPaxComponent,
    BookingComponent,
    SelectProductComponent,
    SelectScheduleComponent,
    SelectBookingPaxComponent,
    SelectScheduleComponent,
    FooterComponent,
    CalendarComponent,
    OfflineComponent,
    SupportComponent,
    SupportReservationComponent,
    ConfirmationComponent,
    LoaderComponent,
    PaymentLinkViewComponent,
    ButtonComponent,
    CardTransportationComponent,
    SelectComponent,
    TagComponent,
    TagActionComponent,
    // SelectAutocompleteComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    NgxMatIntlTelInputComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectModule,
    MatAutocompleteModule,
    SelectAutocompleteComponent,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatStepperModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule
  ],
  providers: [
    AppService,
    DatePipe,
    KlaviyoService,
    ThemeService,
    /*{
      provide : APP_INITIALIZER,
      useFactory : initialize,
      deps : [AppService,AuthService],
      multi : true
    },
    {
      provide: LOCALE_ID,
      useFactory : initializeLocale,
      deps : [AppService]
    },*/
    {
      provide : HTTP_INTERCEPTORS,
      useClass : AuthApiInterceptor,
      multi : true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: 'googleTagManagerId',
      useValue: "GTM-NSJKZD"
    }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
