<div [ngClass]="{ 'product--preselected' : disabled }" class="product-select--container border border--light">
  <form [formGroup]="productForm">
    <div *ngIf="disabled; then disabledBlock; else enabledBlock"></div>

    <ng-template #disabledBlock>
      <p>{{ getProductSelected() }}</p>
    </ng-template>

    <ng-template #enabledBlock>
      <mat-select
        class="select"
        placeholder="{{ 'HOME.PLACEHOLDER_SELECT_PRODUCT' | translate }}"
        formControlName="product"
      >
        <mat-option *ngFor="let product of productList" [value]="product">
          <span>{{ product.name }}</span>
        </mat-option>
      </mat-select>
    </ng-template>
  </form>
</div>
