export const environment = {
  production: false,
  MAIN_URL: 'https://qaebsicr.venturapark.com/api/',
  ZONES: {
    GET_ZONES: 'zones',
  },
  HOTELS: {
    GET_HOTELS: 'hotels',
  },
  TRANSPORTATION: {
    GET: 'web/transportations',
  },
  BANKS: {
    PAYPAL: 'payment/paypal',
    PLUGNPAY: 'payment/plugnpay',
  },
  ADDONS: {
    TRASNPORTATION: {
      LOCATIONS: [
        2, // Cancun
        4, // Riviera Maya
        7, // Ventura Park
        8, // Selvatica
      ],
    },
    ADO: 317,
  },
  BANKS_AVAILABLE: {
    LOCATIONS: {
      2: ['MIT*', 'PAYPAL'], // Cancun
      4: ['MIT*', 'PAYPAL'], // Riviera Maya
      7: ['MIT', 'PAYPAL', 'PLUGNPAY'], // Ventura Park
      8: ['MIT', 'PAYPAL'], // Selvatica
    },
  },
  URL_SELVATICA: 'https://tdc.selvatica.com.mx',
  URL_VENTURA: 'https://www.venturapark.com',
  URL_CANCUN: 'https://dolphinconnectionmx.com/',
  URL_RIVIERA_MAYA: 'https://dolphinconnectionmx.com/',
  MAX_PAX: 20,
  BOOKING_TRAVEL: {
    LOCATIONS: {
      2: 0, // Cancun
      4: 0, // Riviera Maya
      7: 0, // Ventura Park
      8: 0, // Selvatica
    },
  },
  MAIN_PAX: 2,
  TOTAL_MIN_PAYMENT: {
    2: {
      // Cancun
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    4: {
      // Riviera Maya
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    7: {
      // Ventura Park
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    8: {
      // Selvatica
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
  },
  PAYPAL_MODE: 'sandbox',
  PAYPAL_SANDBOX_CLIENT_ID:
    'Aa4eaI5fBGlwvRTzzM9M3hzkksni2SGJeO-buvGnG7ywVkmJqZt2xDPWYfa__ipjaKdeCnhJttdWJHHV',
  PAYPAL_SANDBOX_CLIENT_SECRET:
    'EG7ZP66aSWXuq1XxCKAiIvcwkwq9dWfjizh43mnl1PxRYwCMJ1PzrPmoaoTQvQDTbOYApBJfjw1bXzgp',
  PAYPAL_LIVE_CLIENT_ID:
    'ASkZ9kRcxXTWyjASCcdoGo-YXkO3ESUNs680nL5rUinC2tXVnkCXYhatvmrCX38KNR6kLaBIZCVzuBNw',
  PAYPAL_LIVE_CLIENT_SECRET:
    'EImvoA-OoOaQGeAxbUZj_MDG0YaHHXQmoNTNjK-cV7YMhmy0pEvHphnnWuWNY3pB5XzPZsKpO-HgqTP6',
  GTM_ID: '',
  groupFlag: 20,
  firebase: {
    apiKey: 'AIzaSyDai3rtRMjSfuPBs6q0l7LCKqBGrK8_zik',
    authDomain: 'icaro-qa.firebaseapp.com',
    databaseURL: 'https://icaro-qa-default-rtdb.firebaseio.com',
    projectId: 'icaro-qa',
    storageBucket: 'icaro-qa.appspot.com',
    messagingSenderId: '679608088260',
    appId: '1:679608088260:web:2ea763004b8597279dbb91',
  },
};


