import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountRuleEnum } from 'src/app/Interfaces/Enums/DiscountType.enum';
import { IPax, PaxTypeEnum } from 'src/app/models/Pax.model';
import { DiscountService } from 'src/app/services/discount/discount.service';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-select-booking-pax',
  templateUrl: './select-booking-pax.component.html',
  styleUrls: ['./select-booking-pax.component.css'],
})
export class SelectBookingPaxComponent implements OnInit {
  @Input() pax: IPax | null = null;
  @Input() paxProductQuantity: number = 0;
  @Input() paxMainQuantity: number = 0;
  @Input() paxRuleInDiscount: DiscountRuleEnum = DiscountRuleEnum.DEFAULT;

  @Output() onChange: EventEmitter<IPax> = new EventEmitter();

  public availablePax: number = environment.MAX_PAX;
  public paxMin: number = 0;
  public paxMultiple: number = 1;

  constructor(private discountService: DiscountService) {}

  ngOnInit() {
    this.discountService.discount$.subscribe((discount) => {
      if (discount) {
        if (discount.BOGOA > 0 && discount.BOGOB > 0) {
          if (this.pax) {
            if (this.pax?.id == PaxTypeEnum.SINGLE) {
              this.paxMin = discount.BOGOA;
              this.paxMultiple = discount.BOGOA;

              // if (
              //   this.IS_A_MULTIPLE_OF(this.pax?.quantity, discount.BOGOA)
              //   //this.IS_PAIR(discount.BOGOA) &&
              //   //this.IS_ODD(this.pax.quantity)
              // ) {
              //   this.pax.quantity = discount.BOGOA;
              // }

              this.pax.quantity = discount.BOGOA;
              // else this.pax.quantity = 1;
            }

            if (this.pax?.id == PaxTypeEnum.CHILD) {
              this.paxMin = discount.BOGOB;
              this.paxMultiple = discount.BOGOB;
            }

            this.onChange.emit(this.pax);
          }
        } else
        if (discount.BOGOA > 0 && discount.BOGOB == 0) {
          if (this.pax) {
            if (this.pax?.id == PaxTypeEnum.SINGLE) {
              this.paxMin = discount.BOGOA;
              this.paxMultiple = discount.BOGOA;

              if (discount.BOGOA > 0 && this.IS_PAIR(discount.BOGOA) && this.IS_ODD(this.pax.quantity)) {
                this.pax.quantity = discount.BOGOA;
              }

              this.onChange.emit(this.pax);
            }

            if (this.pax?.id == PaxTypeEnum.CHILD) {
              //this.paxMin = discount.BOGOB;
              this.paxMultiple = 1;
            }
          }
        }
      } else {
        if (this.pax) {
          if (this.pax?.id == PaxTypeEnum.SINGLE) {
            this.paxMin = 1;
            if (this.pax.quantity == 0) {
              this.pax.quantity = environment.MAIN_PAX;
              this.onChange.emit(this.pax);
            }
          } else if (this.pax?.id == PaxTypeEnum.CHILD) {
            this.paxMin = 0;
          }
        }
        this.paxMultiple = 1;
      }
    });
  }

  add() {
    if (this.pax) {
      this.pax.quantity += this.paxMultiple;
      this.onChange.emit(this.pax);
    }
  }

  remove() {
    if (this.pax) {
      if (this.pax.quantity >= this.paxMin) {
        this.pax.quantity -= this.paxMultiple;
        this.onChange.emit(this.pax);
      }
    }
  }

  isDisabled(action: string) {
    if (this.pax) {
      if (this.paxRuleInDiscount == DiscountRuleEnum.NO_MORE_CHILDREN_THAN_ADULTS) {
        return this.NO_MORE_CHILDREN_THAN_ADULTS(this.pax, action);
      }
      else {
        return this.CHILDREN_MORE_OR_LESS_THAN_ADULTS(this.pax, action);
      }
    }
    return true;
  }

  NO_MORE_CHILDREN_THAN_ADULTS(pax: IPax, action: string) {
    //adults
    if (pax.id == PaxTypeEnum.SINGLE) {
      if (action == 'minus') {
        let paxChildren = this.paxProductQuantity - pax.quantity;
        return pax.quantity <= this.paxMin || paxChildren == pax.quantity;
      }

      if (action == 'plus') {
        let paxProjection = this.paxProductQuantity + this.paxMultiple;
        return paxProjection >= this.availablePax || this.paxProductQuantity == this.availablePax;
      }
    }

    //children
    if (pax.id == PaxTypeEnum.CHILD) {
      if (action == 'minus') {
        return (
          pax.quantity <= this.paxMin || pax.quantity > this.paxMainQuantity
        );
      }

      if (action == 'plus') {
        return (
          this.paxProductQuantity == this.availablePax ||
          this.paxMainQuantity <= pax.quantity
        );
      }
    }

    return true;
  }

  CHILDREN_MORE_OR_LESS_THAN_ADULTS(pax: IPax, action: string) {
    //adults
    if (pax.id == PaxTypeEnum.SINGLE) {
      if (action == 'minus') {
        return pax.quantity <= this.paxMin;
      }

      if (action == 'plus') {
        return this.paxProductQuantity == this.availablePax;
      }
    }

    //children
    if (pax.id == PaxTypeEnum.CHILD) {
      if (action == 'minus') {
        return pax.quantity
          ? !(this.paxProductQuantity <= this.availablePax)
          : pax.quantity == this.paxMin;
      }

      if (action == 'plus') {
        return this.paxProductQuantity == this.availablePax;
      }
    }

    return true;
  }

  IS_PAIR(value: number): boolean {
    return value % 2 === 0;
  }

  IS_ODD(value: number): boolean {
    return value % 2 !== 0;
  }

  IS_A_MULTIPLE_OF(value: number, multiple: number) {
    return value % multiple === 0;
  }
}
