<app-loader *ngIf="showLoader"></app-loader>

<ng-container *ngIf="!showLoader">
    <div class="resume-column">
        <app-shopcar-resume></app-shopcar-resume>
        <div class="payment-block">
            <p class="title">{{"PAYMENTS.PAYMENTS_ACCEPT" | translate}}</p>
            <div class="payments-methods">
                <img src="assets/payments/visa.svg" alt="">
                <img src="assets/payments/mastercard.svg" alt="">
                <img src="assets/payments/paypal.svg" alt="">
            </div>
        </div>
    </div>
    <div class="booking-column">
        <h2 class="text-icon-layout section-subtitle section-subtitle--green">
            <mat-icon fontIcon="lock"></mat-icon>
            <span class="section-disclaimer">{{"PAYMENTS.PAYMENT_TRUSTLY" | translate}}</span>
        </h2>
        <!-- PRODUCT AREA -->
        <div
            class="home-container--widget-block">
            <span class="home-container--title">{{ 'HOME.TITLE_1'| translate}}</span>
            <ng-container
                *ngIf="serviceList$ | async as serviceList">
                <app-select-product-new
                    [disabled]="productSelectDisabled"
                    [productList]="serviceList"
                    (onServiceSelected)="onServiceSelected($event)"
                >
                </app-select-product-new>
            </ng-container>
        </div>
        <ng-container *ngIf="booking$ | async as booking">
            <!-- CALENDAR AREA -->
            <!-- [hasPromotion]="" -->
            <div class="home-container--widget-block" [ngClass]="{'disabled' : booking.bookingService === false}">
                <span class="home-container--title">{{ 'HOME.TITLE_2'| translate}}</span>
                <ng-container *ngIf="inventories$ | async as inventories">
                    <app-calendar *ngIf="booking.bookingCalendar == false"
                        [inventoryList]="inventories"
                        [inventoryWeek]="booking.promotionWeek"
                        [daysInAdvance]="booking.daysInAdvance"
                        (onCheckInDateSelected)="onSelectDate($event)"
                        (onChangeMonth)="onChangeCalendar($event,booking.principalProductId)">
                    </app-calendar>
                </ng-container>
                <div (click)="showCalendar()" *ngIf="booking.bookingCalendar"
                    class="card-resume-container border border--light">
                    <div class="card-resume-container--block">
                        <span>{{ booking.checkInDate| date:'longDate' }}</span>
                        <mat-icon aria-hidden="false" fontIcon="arrow_drop_down"></mat-icon>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let product of booking.productList;let index = index">
                <span class="section-subtitle" *ngIf="index > 0">{{ "BOOKING.COMBO_TEXT" | translate }}</span>
                <div class="booking-product">
                    <div>
                        <span class="section-title">{{product.name}}</span>
                    </div>
                    <!-- PAX AREA -->
                    <div *ngIf="index == 0" class="home-container--widget-block">
                        <div *ngFor="let paxItem of product.paxList" class="container--widget">
                            <app-select-booking-pax
                              [pax]="paxItem"
                              [paxMainQuantity]="product.paxList[0].quantity"
                              [paxProductQuantity]="product.availablePaxes"
                              [paxRuleInDiscount]="booking.rulePax"
                              (onChange)="onChangePaxes($event,product.id)">
                            </app-select-booking-pax>
                            <!-- <code [innerHTML]="paxItem | json"></code> -->
                        </div>
                    </div>
                    <!-- SCHEDULE AREA -->
                    <div class="home-container--widget-block">
                        <span class="title" *ngIf="product.locationId == 7 && index == 0">
                            {{ 'BOOKING.SCHEDULE_TITLE'| translate}}
                        </span>
                        <span class="title" *ngIf="product.locationId == 8 || index != 0">
                            {{ 'BOOKING.ACTIVITIE_TITLE'| translate}}
                        </span>

                        <app-select-schedule
                          [principalLocationId]="booking.principalLocationId"
                          [productType]="booking.principalProductType"
                          [free-selection]="[2, 8].includes(product.locationId)"
                          [self-selected]="product.locationId == 7"
                          [productLocationId]="product.locationId"
                          [scheduleList]="optionsForSelectSchedule(product)"
                          (onScheduleSelected)="onScheduleSelected($event,product.id)">
                        </app-select-schedule>
                    </div>
                    <!-- <code [innerHTML]="product | json"></code> -->
                </div>
                <mat-divider></mat-divider>
            </ng-container>
            <!-- <code [innerHTML]="booking | json"></code> -->
        </ng-container>
        <div class="button-section">
            <button
            [ngClass]="{'disabled' : nextDisabled}"
                (click)="goToClientForm()"
                class="button button--primary mobile">
                <span class="button--text">{{ "BUTTON.CONTINUE" | translate }}</span>
            </button>
        </div>
    </div>
</ng-container>