import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ISchedule } from 'src/app/models/Schedule.model';
import { ProductTypeEnum } from 'src/app/models/enums/ProductType.enum';

@Component({
  selector: 'app-select-schedule',
  templateUrl: './select-schedule.component.html',
  styleUrls: ['./select-schedule.component.css'],
})
export class SelectScheduleComponent {
  @Input('principalLocationId') principalLocationId: number | null = null;
  @Input('productLocationId') productLocationId: number | null = null;
  @Input('scheduleList') scheduleList: ISchedule[] = [];
  @Input('productType') productType: ProductTypeEnum | null = null;
  @Input('self-selected') selfSelected: boolean = true;
  @Input('free-selection') freeSelection: boolean = false;

  @Output('onScheduleSelected') onScheduleSelected: EventEmitter<ISchedule> =
    new EventEmitter();

  public scheduleForm: FormGroup = new FormGroup({
    schedule: new FormControl(''),
  });

  constructor() {}

  ngOnInit() {
    this.scheduleForm.valueChanges.subscribe((data) => {
      data.schedule.selected = true;
      this.onScheduleSelected.emit(data.schedule);
    });
  }

  ngOnChanges() {
    let scheduleIndex = this.scheduleList.findIndex(
      (schedule) => schedule.selected === true
    );

    if (this.freeSelection) {
      let index = this.scheduleList.length <= 1 ? 0 : this.scheduleList.length - 1;
      this.scheduleForm.setValue({ schedule: this.scheduleList[index] });
      this.onScheduleSelected.emit(this.scheduleForm.value.schedule);

      return;
    }

    if (scheduleIndex !== -1) {
      return this.scheduleForm.setValue({
        schedule: this.scheduleList[scheduleIndex],
      });
    }

    if (this.scheduleList.length <= 1) {
      this.scheduleForm.setValue({ schedule: this.scheduleList[0] });
      this.onScheduleSelected.emit(this.scheduleForm.value.schedule);

      return;
    }

    if (
      this.principalLocationId == 7 &&
      this.productType == ProductTypeEnum.COMBO &&
      this.productLocationId == 2 &&
      this.scheduleList.length >= 3
    ) {
      this.scheduleList.splice(0, this.scheduleList.length - 2);
    }

    this.scheduleForm.setValue({ schedule: this.scheduleList[this.scheduleList.length - 1] });

    this.onScheduleSelected.emit(this.scheduleForm.value.schedule);

    return;
  }
}
