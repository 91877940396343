import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { AddonService } from 'src/app/services/addon/addon.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';
import { TransportationService } from 'src/app/services/transportation/transportation.service';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { ICurrency } from 'src/app/models/Currency.model';
import { IShopcart } from 'src/app/models/Shopcart.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiscountService } from 'src/app/services/discount/discount.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { RouterService } from 'src/app/services/router/router.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ITransportation } from 'src/app/models/Transportation.model';
import { IProductShopcart } from "src/app/models/ProductShopcart.model";

@Component({
  selector: 'app-shopcar-resume',
  templateUrl: './shopcar-resume.component.html',
  styleUrls: ['./shopcar-resume.component.css'],
})
export class ShopcarResumeComponent {
  @Output() onPaymentSection = new EventEmitter<boolean>();

  public showCollapse: boolean = false;
  public showCollapseDiscount: boolean = false;
  public showDiscountError: boolean = false;
  public showDiscountErrorDaysInAdvance: boolean = false;
  public showDiscountErrorNotApplicable: boolean = false;

  public daysInAdvance: number = 0;
  public showDiscount: boolean = false;

  public bookingFull: boolean = false;

  public stepEnum = StepEnum;

  public total$!: Observable<number>;
  public currency$!: Observable<ICurrency>;
  public shopcart$: Observable<IShopcart> = new Observable();
  public step$: Observable<StepEnum> = new Observable();
  public stepBlock$: Observable<boolean> = new Observable();
  public bogo: number = 0;

  public discountForm: FormGroup = new FormGroup({
    discount: new FormControl('', [Validators.required]),
  });

  public discountFormValid: boolean = false;

  constructor(
    private currencyService: CurrencyService,
    private shopcartService: ShopcartService,
    private addonService: AddonService,
    private discountService: DiscountService,
    private transportationService: TransportationService,
    private bookingService: BookingService,
    private toastService: ToastService,
    private routerService: RouterService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.url.subscribe((url) => {
      // Check the route or any condition you need
      this.showDiscount = !url.some((segment) =>
        segment.path.includes('payment')
      );
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  ngOnInit() {
    this.showDiscount = this.discountService.getCurrentDiscount() == null;
    this.total$ = this.shopcartService.total$;
    this.currency$ = this.currencyService.currency$;
    this.shopcart$ = this.shopcartService.shopcart$;
    this.step$ = this.routerService.stepBooking$;
    this.stepBlock$ = this.routerService.stepBlock$;

    this.checkScreenSize();
    this.discountForm.statusChanges.subscribe((data) => {
      if (data == 'VALID') {
        this.discountFormValid = true;
      }

      if (data == 'INVALID') {
        this.discountFormValid = false;
      }
    });

    this.bookingService.booking$.subscribe((booking) => {
      if (
        booking.bookingService &&
        booking.bookingCalendar &&
        booking.bookingPax &&
        booking.bookingSchedule
      ) {
        this.bookingFull = true;
      } else {
        this.bookingFull = false;
      }
    });
  }

  onClickCollapse() {
    this.showCollapse = !this.showCollapse;
  }

  onDeleteAddon(addon: IAddon) {
    this.addonService.removeAddon(addon);
  }

  //#region Transportation
  onDeleteTransportation(transportation: ITransportation | null) {
    this.transportationService.removeTransportation(transportation);
  }
  //#endregion

  //#region Section Card Discount
  async onApplyDiscount(event: any) {
    // console.log('onApplyDiscount');
    try {
      this.showDiscountError = false;
      this.showDiscountErrorDaysInAdvance = false;
      this.showDiscountErrorNotApplicable = false;

      let shopcartCurrent = this.shopcartService.getShopcart();
      let checkInDate = shopcartCurrent.checkInDate;
      let discountCode = this.discountForm.value.discount;

      let dayOfWeekCheckInDate = checkInDate?.getDay();

      if (!checkInDate) {
        throw false;
      }

      let responseDiscount = await this.discountService.getDiscount(
        discountCode,
        checkInDate
      );
      let differenceInDays = this.differenceInDays(new Date(), checkInDate);

      if (
        responseDiscount.daysInAdvance != null &&
        responseDiscount.daysInAdvance > 0 &&
        differenceInDays < responseDiscount.daysInAdvance
      ) {
        //this.toastService.showToast(`${responseDiscount.code}`);
        this.daysInAdvance = responseDiscount.daysInAdvance;
        this.showDiscountErrorDaysInAdvance = true;
        return; //throw false;
      }

      if (
        responseDiscount.productList.filter((discountByProduct) =>
          shopcartCurrent.productList
            .map((shopcartByProduct) => shopcartByProduct.id)
            .includes(discountByProduct.id)
        ).length == 0
      ) {
        this.showDiscountErrorNotApplicable = true;
        return; //throw false; //new COUPON_NOT_APPLICABLE_ERROR(this.translateService);
      }

      this.bogo = responseDiscount.BOGOA;
      this.shopcartService.setDiscount(responseDiscount);
      this.bookingService.loadDiscount(responseDiscount);
      this.showCollapseDiscount = false;

      if (!this.bookingFull) {
        this.routerService.setBlock(false);
      }

      this.showDiscount = false;

      this.toastService.showToast(`${responseDiscount.code}`);
    } catch (error) {
      this.showDiscount = true;
      this.showDiscountError = true;
      this.routerService.setBlock(true);

      // if (error instanceof COUPON_NOT_APPLICABLE_ERROR) {
      //   this.toastService.showToast(`${error.message}`, 'info');
      // }
    }
  }

  public showDiscountInput() {
    this.showCollapseDiscount = true;
  }

  public cleanSpecialCharacters(event: any) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  public clearDiscountForm(event: any) {
    this.showCollapseDiscount = false;
    this.showDiscountError = false;
    this.showDiscountErrorDaysInAdvance = false;
    this.showDiscountErrorNotApplicable = false;

    this.discountForm.reset();
    this.routerService.setBlock(false);
  }
  //#endregion

  private differenceInDays(dateStart: Date, dateEnd: Date) {
    let differenceInTime = dateEnd.getTime() - dateStart.getTime();
    return Math.round(differenceInTime / (1000 * 3600 * 24));
  }

  private checkScreenSize() {
    this.showCollapse = window.innerWidth > 768; // Adjust the threshold based on your design
  }

  public clearDiscount() {
    this.bogo = 0;
    this.discountForm.reset();
    this.shopcartService.removeDiscount();
    this.bookingService.unloadDiscount();

    this.showDiscount = true;

    if (this.showDiscountError) {
      this.showDiscountError = false;
      this.showDiscountErrorDaysInAdvance = false;
      this.showDiscountErrorNotApplicable = false;
      this.routerService.setBlock(false);
    }
  }

  public nextPage() {
    let payment = this.routerService.goToNext();

    if (payment) {
      this.onPaymentSection.emit(true);
    }
  }

  public onBlurDiscount(event: any) {
    if (!event.relatedTarget?.classList.contains('card-discount--button')) {
      this.showCollapseDiscount = false;
    }
  }

  public showPromocodeByProduct(product: IProductShopcart) {
    let { discountCode, comboId, productList } =
      this.shopcartService.getShopcart();

    if (comboId || product.discount > 0) {
      return discountCode && product.id == productList[0].id;
    } else {
      return false;
    }
  }
}
