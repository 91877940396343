import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
  // standalone: true,
})
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value % 60;
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    const secondsString = seconds < 10 ? '0' + seconds : seconds;
    return `${minutesString}:${secondsString}`;
  }
}
