import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Component } from '@angular/core';
import { environment } from 'src/environment/environment';
import {
  ActivatedRoute,
  EventType,
  NavigationEnd,
  Router,
} from '@angular/router';

import { AppService } from './services/app/app.service';
import { ThemeService } from './services/theme/theme.service';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { IConfig } from './Interfaces/States/Config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  loading$: Observable<boolean> = new Observable();

  public showHeader: boolean = true;
  public showCloseDialog: boolean = false;

  private unsubscribe$ = new Subject<void>();

  public locationName$: Observable<string> = new Observable<string>();
  public logoUrl$: Observable<string> = new Observable<string>();
  public href$: Observable<string> = new Observable<string>();

  public breadcrumbItems: { label: string; link: string }[] = [];

  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private themeService: ThemeService
  ) {
    this.loading$ = new BehaviorSubject<boolean>(true);
  }

  ngOnInit(): void {
    this.locationName$ = this.appService.locationName$;
    this.logoUrl$ = this.appService.logo$;
    this.href$ = this.appService.privatePolicyUrl$;

    this.router.events.subscribe((events) => {
      if (events.type == EventType.NavigationEnd) {
        let dataRoute = this.activatedRoute.snapshot.children;
        for (let event of dataRoute) {
          let link = `${event.routeConfig?.path}`;
          let lang = this.appService.getCurrentLang();

          if (event.queryParams['tk']) {
            link += `?tk=${event.queryParams['tk']}`;
          }

          this.breadcrumbService.addBreadcrumb({
            label: event.data[lang],
            link: link,
          });
        }

        document.querySelector('.global-loading')?.remove();
      }
    });

    this.locationName$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        switch (value) {
          case 'Ventura Park':
            this.themeService.Ventura();
            break;
          case 'Selvatica':
            this.themeService.Selvatica();
            break;
        }
      });

    // this.logoUrl$.subscribe((status) => {
    //   setTimeout(() => {
    //     this.loading$ = new BehaviorSubject<boolean>(status == null);
    //   }, 3000);
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {}

  public openCloseDialog(event: boolean) {
    this.showCloseDialog = true;
  }

  public closeDialogEvent(event: boolean) {
    this.showCloseDialog = false;
  }

  public closeAppEvent(event: boolean) {
    let targetOrigin: string = 'UNDEFINED';
    const app = this.appService.getAppConfig();

    switch (app.locationId) {
      case 8: //'Selvatica'
        targetOrigin = environment.URL_SELVATICA;
        break;
      case 7: //'Ventura Park'
        targetOrigin = environment.URL_VENTURA;
        break;
    }

    window.parent.postMessage('closeModal', targetOrigin);
  }
}
