import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environment/environment';
import { lastValueFrom, map } from 'rxjs';
import { ShopcartService } from '../shopcart/shopcart.service';
import { ITransportation } from 'src/app/models/Transportation.model';
import { IPax } from 'src/app/models/Pax.model';
import { IGeneralResponseDTO } from 'src/app/models/responses/GeneralResponse.dto';
import { IGetTransportationDTO } from 'src/app/models/responses/GetTransportationResponse.dto';

@Injectable({
  providedIn: 'root'
})

export class TransportationService {

  private getTransportationUrl: string = `${environment.MAIN_URL}${environment.TRANSPORTATION.GET}`

  constructor(private http: HttpClient, private shopcartService: ShopcartService) { }

  public async getTransportation(hotelId: number): Promise<ITransportation | null> {
    const observable = this.http.post<IGeneralResponseDTO<IGetTransportationDTO[]>>(this.getTransportationUrl, { hotelId: hotelId }).pipe(
      map((response) => this.transportationTransformer(response))
    );

    const response = await lastValueFrom(observable);
    this.shopcartService.addTransportation(response);
    return response;
  }

  public removeTransportation(transportation: ITransportation | null){
    this.shopcartService.removeTransportation(transportation);
  }

  private transportationTransformer(transportation: IGeneralResponseDTO<IGetTransportationDTO[]>): ITransportation | null {
    let transportationList = transportation.data as IGetTransportationDTO[];

    let transportationFormattedList = transportationList.map((transportationItem) => {

      let transportationFormatted: ITransportation = {
        id: transportationItem.addonId,
        name: transportationItem.addonName,
        hotelName: transportationItem.hotelName,
        hotelId : transportationItem.hotelId,
        locationId : transportationItem.locationId,
        locationName : transportationItem.locationName,
        minutesDistance: transportationItem.minutesDistance,
        discountTotal: 0,
        subtotal: 0,
        total: 0,
        dropOff: new Date(),
        pickUp: new Date(),
        pickUpFrontEnd: new Date(),
        paxList: transportationItem.paxList.map((pax: any) => {
          let paxItem: IPax = {
            id: pax.paxId,
            name: pax.paxName,
            price: pax.paxPrice,
            blocked: false,
            subtotal: 0,
            total: 0,
            priority: 0,
            quantity: 0
          }
          return paxItem;
        })
      }
      return transportationFormatted;
    })

    return transportationFormattedList.length > 0 ? transportationFormattedList[0] : null;
  }

}
