export const environment = {
    MAIN_URL : 'https://qaebsicr.venturapark.com/api/',
    ZONES : {
      GET_ZONES : "zones",
    },
    HOTELS : {
      GET_HOTELS : "hotels"
    },
    TRANSPORTATION:{
      GET : "web/transportations"
    },
    BANKS:{
      PAYPAL : "payment/paypal"
    },
    ADDONS: {
      TRASNPORTATION: {
        LOCATIONS: [
          7, //'Ventura Park'
          8, //'Selvatica'
        ],
      },
    },    
    BANKS_AVAILABLE: {
      LOCATIONS: {
        7: ['MIT', 'PAYPAL'], //'Ventura Park'
        8: ['MIT', 'PAYPAL'], //'Selvatica'
      },
    },
    URL_SELVATICA : "https://tdc.selvatica.com.mx",
    URL_VENTURA : "https://www.venturapark.com",
    MAX_PAX : 20,
    BOOKING_TRAVEL: {
     LOCATIONS: {
       7: 0, //'Ventura Park'
       8: 0, //'Selvatica'
     },
    },
    TOTAL_MIN_PAYMENT: {
    2: {
      // Cancun
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    7: {
      // Ventura Park
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    8: {
      // Selvatica
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
  },
    MAIN_PAX : 2,
    PAYPAL_MODE : "sandbox",
    PAYPAL_SANDBOX_CLIENT_ID:"Aa4eaI5fBGlwvRTzzM9M3hzkksni2SGJeO-buvGnG7ywVkmJqZt2xDPWYfa__ipjaKdeCnhJttdWJHHV",
    PAYPAL_SANDBOX_CLIENT_SECRET:"EG7ZP66aSWXuq1XxCKAiIvcwkwq9dWfjizh43mnl1PxRYwCMJ1PzrPmoaoTQvQDTbOYApBJfjw1bXzgp",
    PAYPAL_LIVE_CLIENT_ID:"ASkZ9kRcxXTWyjASCcdoGo-YXkO3ESUNs680nL5rUinC2tXVnkCXYhatvmrCX38KNR6kLaBIZCVzuBNw",
    PAYPAL_LIVE_CLIENT_SECRET:"EImvoA-OoOaQGeAxbUZj_MDG0YaHHXQmoNTNjK-cV7YMhmy0pEvHphnnWuWNY3pB5XzPZsKpO-HgqTP6",
    GTM_ID : '',
    production : false,
    groupFlag : 20,
    firebase: {
	apiKey: 'AIzaSyDai3rtRMjSfuPBs6q0l7LCKqBGrK8_zik',
	authDomain: 'icaro-qa.firebaseapp.com',
	databaseURL: 'https://icaro-qa-default-rtdb.firebaseio.com',
	projectId: 'icaro-qa',
	storageBucket: 'icaro-qa.appspot.com',
	messagingSenderId: '679608088260',
	appId: '1:679608088260:web:2ea763004b8597279dbb91',
    },
};
