import { Component, Signal, computed, signal } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { IReservation } from 'src/app/Interfaces/Models/Reservation.interface';
import { BankTypeEnum } from 'src/app/models/enums/Bank.enum';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { BanksService } from 'src/app/services/banks/banks.service';
import { HttpService } from 'src/app/services/http/http.service';
import { PaypalService } from 'src/app/services/paypal/paypal.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { RouterService } from 'src/app/services/router/router.service';
import { IPaypal } from 'src/app/models/Paypal.model';

@Component({
  selector: 'app-payment.view',
  templateUrl: './payment.view.component.html',
  styleUrls: ['./payment.view.component.css'],
})
export class PaymentViewComponent {
  public paymentGatewaySignal = signal<BankTypeEnum | null>(null);
  public paymentMethods = BankTypeEnum;
  public reservationId: string = '';
  public reservation!: IReservation;

  public mitPaymentShow: boolean = false;
  public mitpayment: string = '';

  public showLoader: boolean = true;
  public showPaymentError: boolean = false;
  public showPayments: boolean = true;

  // observers-
  public banks$ = this.bankService.bank$;
  public reservation$ = this.reservationService.reservation$;

  // subscriptions
  public firebaseSubscription: Subscription = new Subscription();

  //signals
  public paypalClient = computed(() => this.paypalService.paypal().clientId);
  public paypalLoading = this.paypalService.paypalLoading;

  constructor(
    private bankService: BanksService,
    private activedRoute: ActivatedRoute,
    private reservationService: ReservationService,
    private firebaseRealTimeService: AngularFireDatabase,
    private httpService: HttpService,
    private router: Router,
    private routerService: RouterService,
    private paypalService: PaypalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.routerService.setStep(StepEnum.PAYMENT);
    this.reservationId = this.reservationService.getReservationId();

    // logic for payment url
    let reservationParam =
      this.activedRoute.snapshot.paramMap.get('reservationId');

    if (reservationParam) {
      this.showPayments = false;
      this.reservationId = reservationParam;
      await this.reservationService.setUpReservation(reservationParam);
    }

    // check payment methods available
    let bankList = await this.bankService.getBanks();

    for await (let bank of bankList) {
      // console.log(bank);

      if (bank.type === this.paymentMethods.MIT) {
        let responseCreateOrderMIT = await this.httpService.createMITOrder(
          this.reservationId,
          bank.id
        );

        if (typeof responseCreateOrderMIT == 'string') {
          this.mitPaymentShow = true;
          this.mitpayment = responseCreateOrderMIT;

          this.firebaseSubscription = this.firebaseRealTimeService
            .object(`reservations/${this.reservationId}`)
            .valueChanges()
            .subscribe(async (data: any) => {
              // if the payments attempts is more than 3 or equal, always send to support page
              if (data['paymentAttempts'] >= 3) {
                this.reservationService.setReservationId(data['reservationId']);
                this.router.navigate(['/support-reservation']);
              }

              // if the payment status are true send to confirm page
              if (data['paymentStatus']) {
                this.showPaymentError = false;
                this.router.navigate([
                  '/reservation-confirmation',
                  data.reservationId,
                ]);
              }

              if (data['paymentError']) {
                this.mitpayment = await this.httpService.createMITOrder(
                  this.reservationId,
                  bank.id
                );
                this.showPaymentError = true;
              }
            });
        } else {
          bank.status = false;
          // bankList.push(bank);
        }
      }

      if (bank.type == this.paymentMethods.PAYPAL) {
        // console.log('me ejecuto');
        await this.paypalService.getPaypal(bank.id);
      }
    }

    let isOnlyOneProcessorAvailable = bankList.filter((i) => i.status).length == 1;
    if (isOnlyOneProcessorAvailable)
      this.setPaymentGateway(bankList.filter((i) => i.status).at(0)?.type ?? null);

    this.showLoader = false;
  }

  ngOnDestroy() {
    this.firebaseSubscription.unsubscribe();
  }

  onValidTermsAndConditions(event: any) {
    this.showPayments = true;
  }

  private setPaymentGateway(paymentGateway: BankTypeEnum | null) {
    this.paymentGatewaySignal.set(paymentGateway);
  }

}
