import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  injectStyleFromUrl(url: string) {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Selvatica(url: string = '/assets/themes/selvatica.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Ventura(url: string = '/assets/themes/ventura.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }

  Cancun(url: string = '/assets/themes/cancun.css') {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = url;
    this.renderer.appendChild(document.head, linkElement);
  }
}
