import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CountdownTimerService } from 'src/app/services/countdown-timer/countdown-timer.service';

//#e6007e

@Component({
  selector: 'app-countdown-timer',
  template: ` <div>
    <mat-icon
      aria-hidden="false"
      fontSet="material-icons-outlined"
      style="color: darkslategray;"
      >timer</mat-icon
    >
    <span style="color: darkslategray;">Tiene <strong>{{ remainingTime | time }}</strong> minutos restantes para este pago</span>
  </div>`,
  styleUrls: ['./countdown-timer.component.css'],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  /** Duración del contador en segundos (por defecto 5 minutos) */
  @Input() duration: number = 300;
  @Output() timeUp: EventEmitter<void> = new EventEmitter<void>();
  remainingTime: number = 0;
  private subscription: Subscription | null;

  constructor(private countdownTimerService: CountdownTimerService) {
    this.subscription = null;
  }

  ngOnInit(): void {
    this.countdownTimerService.startCountdown(this.duration);
    this.subscription = this.countdownTimerService.remainingTime$.subscribe(
      (time) => {
        this.remainingTime = time;
        if (time <= 0) {
          this.timeUp.emit();
        }
      }
    );
  }

  ngOnDestroy(): void {
    // this.clearTimer();
    this.subscription?.unsubscribe();
  }
}
