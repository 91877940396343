import { Injectable } from '@angular/core';
import { IShopCart } from 'src/app/Interfaces/Models/ShopCart.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { IPax } from 'src/app/models/Pax.model';
import { IShopcart } from 'src/app/models/Shopcart.model';
import { ISchedule } from 'src/app/models/Schedule.model';
import { ProductTypeEnum } from 'src/app/models/enums/ProductType.enum';
import { IDiscount } from 'src/app/models/Discount.model';
import { DiscountService } from '../discount/discount.service';
import { ITransportation } from 'src/app/models/Transportation.model';
import { DateService } from '../dates/date.service';
import { KlaviyoService } from '../klaviyo/klaviyo.service';

@Injectable({
  providedIn: 'root'
})
export class ShopcartService {

  private shopcart: IShopcart = {
    shopcartTotal: 0,
    shopcartSubtotal: 0,
    shopcartDiscountTotal: 0,
    checkInDate: null,
    productList: [],
    addonList: [],
    transportation: null,
    discountCode: null,
    discountId: null,
    hotelId : null
  }

  private shopcartSubject: BehaviorSubject<IShopcart> = new BehaviorSubject(this.shopcart);
  public shopcart$: Observable<IShopcart> = this.shopcartSubject.asObservable();

  public total: number = 0;
  public totalSubject: BehaviorSubject<number> = new BehaviorSubject(this.total);
  public total$: Observable<number> = this.totalSubject.asObservable();

  constructor(
    private discountService: DiscountService,
    private dateService : DateService,
    private klaviyoService : KlaviyoService,
  ) { }

  // get current shop cart
  public getShopcart() {
    return this.shopcartSubject.value;
  }

  public addProduct(
    productId: number,
    productName: string,
    locationId: number,
    locationName: string,
    productVariant: ProductTypeEnum,
    productImage?: string,
  ) {
    this.shopcart.productList.push({
      id: productId,
      name: productName,
      image: productImage,
      locationId: locationId,
      locationName: locationName,
      paxList: [],
      schedule: null,
      total: 0,
      subtotal: 0,
      discount: 0,
      variant: productVariant,
    });

    this.shopcartSubject.next(this.shopcart);
    return this.shopcart.productList.findIndex((product) => product.id); //&& product.schedule == null

    //this.klaviyoService.Event_AddedToCart();
  }

  public deleteProduct(productId: number): boolean {
    const productIndex = this.shopcart.productList.findIndex(product => product.id == productId)
    if (productIndex < 0) {
      return false;
    }

    this.shopcart.productList.splice(productIndex, 1);
    this.shopcartSubject.next(this.shopcart);
    return true;
  }

  public addSchedule(productId: number, schedule: ISchedule) {
    //&& product.schedule == null
    let productIndex = this.shopcart.productList.findIndex(
      (product) => product.id === productId
    );
    this.shopcart.productList[productIndex].schedule = schedule;
    this.shopcartSubject.next(this.shopcart);
  }

  public addCheckInDate(date: Date) {
    this.shopcart.checkInDate = date;
    this.shopcartSubject.next(this.shopcart);
  }

  public cleanState() {
    this.shopcart = {
      shopcartTotal: 0,
      shopcartSubtotal: 0,
      shopcartDiscountTotal: 0,
      checkInDate: null,
      productList: [],
      addonList: [],
      transportation: null,
      discountCode: null,
      discountId: null,
      hotelId : null,
    }

    this.total = 0;
    this.totalSubject.next(this.total);
    this.shopcartSubject.next(this.shopcart);
  }

  addPax(productId: number, paxSelected: IPax, productIndexIncome?: number): boolean {
    let { id, price, quantity, discount } = paxSelected;
    const paxFormatted = paxSelected;
    let productIndex = this.shopcart.productList.findIndex(product => product.id === productId);

    if (productIndexIncome) {

      productIndex = productIndexIncome;
    }

    const paxList = this.shopcart.productList[productIndex].paxList;
    const paxIndex = paxList.findIndex(pax => pax.id == id);

    paxFormatted.subtotal = parseFloat((price * quantity).toFixed(2));
    paxFormatted.discountTotal = parseFloat(((discount ?? 0) * quantity).toFixed(2));
    paxFormatted.total = parseFloat((paxFormatted.subtotal - paxFormatted.discountTotal).toFixed(2));

    /* if not exist push to array */
    if (paxIndex < 0) {
      this.shopcart.productList[productIndex].paxList.push(paxFormatted);
      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);
      this.totalSubject.next(this.shopcart.shopcartTotal);
      return true;
    }

    /* remove item if exist and the quantity is zero */
    if (paxSelected.quantity == 0) {
      this.shopcart.productList[productIndex].paxList.splice(paxIndex, 1);
      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);
      this.totalSubject.next(this.shopcart.shopcartTotal);
      return true;
    }

    /* modify the current pax object if exist */
    this.shopcart.productList[productIndex].paxList[paxIndex] = paxFormatted;
    /*
    let discountObj = this.discountService.getCurrentDiscount();
    if(discountObj){
      this.shopcart = this.validateBOGO(this.shopcart,discountObj);
    }*/
    this.shopcart = this.calculateTotal(this.shopcart);
    this.shopcartSubject.next(this.shopcart);
    this.totalSubject.next(this.shopcart.shopcartTotal);

    return true;
  }

  //#region Transportation
  public addTransportation(transportation: ITransportation | null) {
    let product = this.shopcart.productList[0];
    if (transportation != null) {
      let transportationFormatted = { ...transportation };

      transportation.paxList.forEach((pax) => {
        let paxIndex = product.paxList.findIndex(
          (productPax) => productPax.id == pax.id && productPax.quantity > 0
        );
        if (paxIndex != -1) {
          pax.quantity = product.paxList[paxIndex].quantity;
          pax.subtotal = pax.quantity * pax.price;
          pax.total = pax.subtotal - (pax.discount ?? 0);
        }
      });

      transportationFormatted.paxList = transportation.paxList.filter(
        (paxItem) => paxItem.quantity > 0
      );
      transportationFormatted.pickUp = product.schedule!.date;
      transportationFormatted.pickUpFrontEnd = this.dateService.minusMinutesToDate(
        transportation.minutesDistance,
        product.schedule!.date
      );
      transportationFormatted.dropOff = this.dateService.plusMinutesToDate(
        transportation.minutesDistance,
        product.schedule!.dateEnd
      );
      console.log(this.shopcart);
      this.shopcart.hotelId = transportationFormatted.hotelId;
      this.shopcart.transportation = transportationFormatted;
      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);
      this.totalSubject.next(this.shopcart.shopcartTotal);
    }
  }

  public removeTransportation(transportation: ITransportation | null){
    this.shopcart.transportation = transportation;
    this.shopcart = this.calculateTotal(this.shopcart);
    this.shopcartSubject.next(this.shopcart);
    this.totalSubject.next(this.shopcart.shopcartTotal);
  }
  //#endregion

  public addAddon(addon: IAddon): boolean {
    const addonHasPaxes = addon.paxList.reduce((counter, pax) => counter + pax.paxQuantity, 0);
    const paxListValid = addon.paxList.filter(pax => pax.paxQuantity > 0);
    addon.paxList = paxListValid;
    const addonIndex = this.shopcart.addonList.findIndex(currentAddon => currentAddon.addonId === addon.addonId);

    if (addonHasPaxes <= 0 && addonIndex < 0) {
      return false;
    }

    if (addonHasPaxes == 0) {
      this.shopcart.addonList.splice(addonIndex, 1);
      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);
      this.totalSubject.next(this.shopcart.shopcartTotal);
      return false;
    }

    if (addonIndex < 0) {
      this.shopcart.addonList.push(addon);
      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);
      this.totalSubject.next(this.shopcart.shopcartTotal);
      return true;
    }

    this.shopcart.addonList[addonIndex] = addon;
    this.shopcart = this.calculateTotal(this.shopcart);
    this.shopcartSubject.next(this.shopcart);
    this.totalSubject.next(this.shopcart.shopcartTotal);
    return true;
  }

  public removeAddon(addon: IAddon) {
    const addonIndex = this.shopcart.addonList.findIndex(currentAddon => currentAddon.addonId === addon.addonId);
    this.shopcart.addonList.splice(addonIndex, 1);
    this.shopcart = this.calculateTotal(this.shopcart);
    this.shopcartSubject.next(this.shopcart);
    this.totalSubject.next(this.shopcart.shopcartTotal);
  }

  private calculateTotal(shopcart: IShopcart): IShopcart {
    let total = 0;
    let subtotal = 0;
    let discount = 0;
    this.shopcart.productList.map(product => {
      product.total = product.paxList.reduce((counter, pax) => counter + pax.total, 0);
      product.subtotal = product.paxList.reduce((counter, pax) => counter + pax.subtotal, 0);
      product.discount = product.paxList.reduce((counter, pax) => counter + (pax.discountTotal ?? 0), 0);

      total += product.total;
      subtotal += product.subtotal;
      discount += product.discount;
    });

    this.shopcart.addonList.map(addon => {
      addon.paxList.forEach(pax => {
        pax.paxTotal = (pax.paxPrice * pax.paxQuantity)
      })
      addon.total = addon.paxList.reduce((counter, pax) => counter + pax.paxTotal, 0);
      addon.subtotal = addon.paxList.reduce((counter, pax) => counter + pax.paxSubtotal, 0);
      addon.discountTotal = addon.paxList.reduce((counter, pax) => counter + pax.paxDiscount, 0);

      total += addon.total;
      subtotal += addon.subtotal;
      discount += addon.discountTotal;
    });

    if (this.shopcart.transportation) {
      this.shopcart.transportation.total = this.shopcart.transportation.paxList.reduce((counter, pax) => counter + pax.total, 0);
      this.shopcart.transportation.subtotal = this.shopcart.transportation.paxList.reduce((counter, pax) => counter + pax.subtotal, 0);
      this.shopcart.transportation.discountTotal = this.shopcart.transportation.paxList.reduce((counter, pax) => counter + (pax.discountTotal ?? 0), 0);

      total += this.shopcart.transportation.total;
      subtotal += this.shopcart.transportation.subtotal;
      discount += this.shopcart.transportation.discountTotal;
    }

    shopcart.shopcartTotal = parseFloat(total.toFixed(2));
    shopcart.shopcartSubtotal = parseFloat(subtotal.toFixed(2));
    shopcart.shopcartDiscountTotal = parseFloat(discount.toFixed(2));
    return shopcart;
  }

  public setCombo(comboId: number) {
    this.shopcart.comboId = comboId;
    this.shopcartSubject.next(this.shopcart);
  }

  public setDiscountCode(discountId: number, discountCode: string) {
    this.shopcart.discountCode = discountCode;
    this.shopcart.discountId = discountId;

    console.log('%c ReservationService.setDiscountCode', 'color: blue;');
    console.log('%c' + JSON.stringify(this.shopcart), 'color: blue;');

    this.shopcartSubject.next(this.shopcart);
  }

  public setDiscount(discount: IDiscount) {
    try {
      // iterate all product of the shopcart to apply the discount
      for (let product of this.shopcart.productList) {
        let discountIndex = discount.productList.findIndex(
          (discountItem) => discountItem.id == product.id
        );

        // if the product dont apply send error
        if (
          discountIndex == -1
          &&
          product.variant != ProductTypeEnum.COMBO
        ) {
          throw false;
        }

        this.shopcart.discountCode = discount.code;
        this.shopcart.discountId = discount.id;

        if (
          product.variant == ProductTypeEnum.COMBO
          &&
          product != this.shopcart.productList[0]
        ) {
          continue;
        }

        for (let pax of product.paxList) {
          let discountPaxIndex = discount.productList[
            discountIndex
          ].paxList.findIndex(
            (discountPaxItem) => discountPaxItem.id == pax.id
          );
          // if the pax dont apply only past to another
          if (discountPaxIndex == -1) {
            continue;
          }

          let discountPerPax =
            discount.productList[discountIndex].paxList[discountPaxIndex].price;
          // apply discount percent
          if (
            discount.productList[discountIndex].paxList[discountPaxIndex]
              .currencyCode == null
          ) {
            discountPerPax = (discountPerPax / 100) * pax.price;
          }

          pax.discount = parseFloat(discountPerPax.toFixed(2));
          pax.discountTotal = parseFloat(
            (discountPerPax * pax.quantity).toFixed(2)
          );
          pax.subtotal = parseFloat((pax.quantity * pax.price).toFixed(2));
          pax.total = parseFloat((pax.subtotal - pax.discountTotal).toFixed(2));
          pax.discountMultiples = discount.BOGOA;
        }
      }

      this.shopcart = this.calculateTotal(this.shopcart);
      this.shopcartSubject.next(this.shopcart);

      this.totalSubject.next(this.shopcart.shopcartTotal);
      this.discountService.setDiscount(discount);
      return true;
    } catch (error) {
      throw error;
    }
  }

  public removeDiscount(){
    this.shopcart.discountCode = null;
    this.shopcart.discountId = null;

    Array.from(this.shopcart.productList).forEach((product) => {
      Array.from(product.paxList).forEach((pax) => {
        pax.discount = 0;
        pax.discountTotal = 0;
        pax.subtotal = parseFloat((pax.quantity * pax.price).toFixed(2));
        pax.total = parseFloat((pax.subtotal - pax.discountTotal).toFixed(2));
        pax.discountMultiples = 0;
      });
    });

    this.shopcart = this.calculateTotal(this.shopcart);
    this.shopcartSubject.next(this.shopcart);

    this.totalSubject.next(this.shopcart.shopcartTotal);
    this.discountService.setDiscount(null);
  }

  /* Utils */

  private findProduct(shopCart: IShopcart, productId: number): number {
    const productIndex = shopCart.productList.findIndex(product => product.id == productId)
    return productIndex;
  }

  private findAddon(shopCart: IShopCart, addonId: number): number {
    const addonIndex = shopCart.addonList.findIndex(addon => addon.addonId == addonId)
    return addonIndex;
  }

  public validateBOGO(shopcart: IShopcart, discount: IDiscount) {

    if (discount.BOGOA <= 0 || discount.BOGOB <= 0) { return shopcart; }

    for (let product of shopcart.productList) {

      for (let pax of product.paxList) {
        const isMultiple = pax.quantity % discount.BOGOA === 0;
        const leftover = isMultiple ? 0 : Math.floor(pax.quantity % discount.BOGOA);

        if (isMultiple) {
          continue;
        }

        // first calculate the discount
        let paxQuantityDiscount = (pax.quantity - leftover);
        pax.discountTotal = (paxQuantityDiscount * (pax.discount ?? 0));
        pax.subtotal = (pax.price * pax.quantity);
        pax.total = (pax.subtotal - (pax.discountTotal ?? 0));
      }
    }
    return shopcart;
  }

  public validatePaxListEmpty(shopcart: IShopCart): boolean {
    const empty = shopcart.productList.reduce((counter, product) => counter + product.paxList.length, 0);
    if (empty == 0) {
      return true;
    }

    return false;
  }

  /* Reset values to interaction  */
  public resetCheckInDate(shopCart: IShopCart) {
    try {
      shopCart.checkInDate = null;

      shopCart.productList.forEach(product => {
        product.checkInTime = null;
        product.checkInDate = null;
      });

      return shopCart;
    } catch (error) {
      throw false;
    }
  }

  public resetTotals(shopCart: IShopCart) {
    try {
      shopCart.total = 0;
      shopCart.subtotal = 0;
      shopCart.discountTotal = 0;
      shopCart.productList.forEach(product => {
        product.total = 0
        product.subtotal = 0;
        product.discountTotal = 0;
      });

      shopCart.addonList.forEach(addon => {
        addon.total = 0
        addon.subtotal = 0;
        addon.discountTotal = 0;
      });
      return shopCart;
    } catch (error) {
      throw false;
    }
  }

  public resetPaxShopCart(shopCart: IShopCart) {
    try {
      shopCart.productList.forEach(product => product.paxList = []);
      shopCart.addonList.forEach(addon => addon.paxList = []);
      return shopCart;
    } catch (error) {
      throw false;
    }
  }
}
