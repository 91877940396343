import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountdownTimerService {
  private _remainingTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private intervalId: any;

  get remainingTime$(): Observable<number> {
    return this._remainingTime.asObservable();
  }

  startCountdown(duration: number): void {
    this._remainingTime.next(duration);
    this.clearTimer();
    this.intervalId = setInterval(() => {
      const currentTime = this._remainingTime.getValue() - 1;
      this._remainingTime.next(currentTime);

      if (currentTime <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  stopCountdown(): void {
    this.clearTimer();
  }
}
